import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import FileSaver from 'file-saver';
import JSZip from 'jszip'; // 打包压缩必备，参考链接https://stuk.github.io/jszip/
import { observable, action, toJS } from 'mobx';
import shortid from 'shortid';
import { api } from '../../../../utils';

interface IGmsGoodsGroupPicDownloadVo {
  groupId: null;
  groupName: string;
  mainPicUrls: string[];
  smallPicUrl: string;
}

interface IPosGoodsPicDownloadVos {
  downloadUrl: null | string;
  groupId: number;
  groupName: string;
  smallPicUrl: string | null;
}

interface GroupPicture {
  gmsGoodsGroupPicDownloadVo?: IGmsGoodsGroupPicDownloadVo;
  posGoodsPicDownloadVos?: IPosGoodsPicDownloadVos[];
}

export default class DownloadPicPackageStore {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public visible = false;

  @observable public parent;

  @observable public gmsGoodsId: number;

  @observable public mainPictures: string[];

  @observable public groupPics = []; // 展示的组图信息

  @observable public dynamicIds;

  @action public onModalShow = (gmsGoodsId: number, dynamicIds: number | string) => {
    Object.assign(this, {
      visible: true,
      gmsGoodsId,
      dynamicIds,
    });
    this.getGroupPic(); // 获取主图信息
  };

  @action public onModalClose = () => {
    this.visible = false;
    this.groupPics = [];
  };

  @action public getGroupPic = async(): Promise<void> => {
    const res = await request<BaseData<GroupPicture>>({
      url: api.getGroupPic,
      method: 'post',
      data: { gmsGoodsId: this.gmsGoodsId },
    });
    try {
      if (res.data.gmsGoodsGroupPicDownloadVo.groupName) {
        this.groupPics = [
          {
            ...res.data.gmsGoodsGroupPicDownloadVo,
            groupId: 0,
          },
          ...res.data.posGoodsPicDownloadVos,
        ];
        this.mainPictures = res.data.gmsGoodsGroupPicDownloadVo.mainPicUrls;
      } else if (res.data.posGoodsPicDownloadVos) {
        this.groupPics = [...res.data.posGoodsPicDownloadVos];
      }
    } catch (err) {
      console.log(err);
    }
    console.log(toJS(this.groupPics));
  };

  @observable public pushPromise = (url: string, category: string, promises: Array<Promise<unknown>>, zip: JSZip, dictName?: string): void => {
    promises.push(
      new Promise((resolve, reject) => {
        this.getFile(url).then((res: Blob) => {
          // 下载文件, 并存成ArrayBuffer对象
          console.log(res, '--blob响应');

          // 获取文件名
          const fileName = category + this.getId() + url.substr(url.lastIndexOf('.')); // 组成类似0.jpg 1.png
          if (dictName) {
            zip.file(`下载图片包/${dictName}/${fileName}`, res, { binary: true }); // 逐个添加文件
          } else {
            zip.file(`下载图片包/${fileName}`, res, { binary: true });
          }
          resolve(true);
        });
      })
    );
  };

  // 下载图片
  @observable public getFile = (url: string): Promise<unknown> => {
    return new Promise((resolve, reject) => {
      request({
        method: 'get',
        withCredentials: false,
        url,
        responseType: 'blob',
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log('getFile error:', error);
          reject(error.toString());
        });
    });
  };

  @observable public getId = () => {
    const id = shortid.generate();
    if (shortid.isValid(id)) {
      return id;
    }
    this.getId();
  };

  // 下载图片包埋点
  @observable public recordDownloadNum = async(groupId: number, groupName: string): Promise<void> => {
    const res = await request({
      url: api.recordDownloadNum,
      method: 'post',
      data: {
        dynamicIds: this.dynamicIds ? this.dynamicIds : '',
        gmsGoodsId: this.gmsGoodsId,
        groupId,
        groupName,
      },
    });
  };

  @observable public onClickDownload = (value: number, groupName: string) => {
    if (value === 0) {
      const zip = new JSZip();
      const promises = [];
      this.mainPictures.forEach((item) => {
        const url = item.split('?')[0];
        this.pushPromise(url, '主图', promises, zip, '主图');
      });

      message.success('下载中');
      Promise.all(promises).then(() => {
        zip
          .generateAsync({ type: 'blob' })
          .then((content) => {
            // 生成二进制流
            FileSaver.saveAs(content, '下载图片包.zip'); // 利用file-saver保存文件
          });
      });
    } else {
      const item = this.groupPics.find((el) => el.groupId === value);
      if (item && item.downloadUrl) {
        window.open(item.downloadUrl);
      }
    }
    this.recordDownloadNum(value, groupName); // 下载图片包埋点
  };
}

export type IDownloadPicPackageStore = DownloadPicPackageStore;
