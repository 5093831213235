import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import uniq from 'lodash/uniq';
import { action, observable } from 'mobx';
import shortid from 'shortid';
import { api, SHELVES_PLATFORM } from '../../../../utils';
import ValidPermissionModalStore from '../../../shelvesNew/validPermissionModal/store';
import DownloadPicPackageStore from '../downloadPicPackage/downloadPicPackageStore';

export default class PublishSuccessStore {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public parent;

  @observable public visible = false;

  @observable public submitData;

  @observable public shopList;

  @observable public goodsId: number;

  @observable public downloadPicPackageStore = new DownloadPicPackageStore({ parent: this });

  @observable public validPermissionModalStore = new ValidPermissionModalStore({ parent: this });

  @observable public gmsStyleNo;

  @observable public gmsGoodsId;

  @observable public dynamicIds;

  @observable public currentGmsGoodsId = []; // 在上架的id

  @observable public gmsGoodsInfoVos = []; // 在上架的id

  @observable public page = 'styleDetail';

  @observable public onSuccessFullModalClose = () => {
    this.visible = false;
  };

  @observable public onSuccessFullModalShow = (submitData, gmsStyleNo: string, gmsGoodsId: number, dynamicIds: string | number, gmsGoodsInfoVos, shopList) => {
    Object.assign(this, {
      visible: true,
      submitData,
      gmsStyleNo,
      gmsGoodsId,
      dynamicIds,
      shopList,
      goodsId: submitData.goodsId,
      gmsGoodsInfoVos,
    });
  };

  @action public onPutAwayCallback = (shopId) => {
    const arr = this.currentGmsGoodsId;
    arr.push(shopId);
    this.currentGmsGoodsId = uniq(arr);
  };

  @action public onPutAway = (shopId) => {
    const gmsGoodsId = this.gmsGoodsInfoVos.find((item) => item.shopId === shopId)?.gmsGoodsId || '';
    const pageId = shortid.generate();

    this.validPermissionModalStore.shopId = shopId;
    request<BaseData<any>>({
      url: api.validePermission,
      method: 'POST',
      data: { shopId },
    }).then((res) => {
      if (res.data) {
        this.visible = false;
        const platformType = res.data.platformType;
        const item = SHELVES_PLATFORM.find((v) => v.shopPlatformType === platformType);
        const url = item?.url || '';
        window.top.egenie.openTab(`${url}?gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&platformType=${platformType}&pageId=${pageId}`, pageId, '上架');
      } else {
        this.validPermissionModalStore.visiblePermission = true;
      }
    })
      .catch(() => {
        this.validPermissionModalStore.visiblePermission = true;
      });
  };
}

export type IPublishSuccessStore = PublishSuccessStore;
