import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import type { ReactElement } from 'react';
import React from 'react';
import styles from './index.less';

const ValidPermissionModal = observer((props): ReactElement => {
  const {
    visiblePermission,
    closeModal,
    gotoAuth,
    authBtnLoading,
  } = props.store;
  return (
    <Modal
      footer={(
        <>
          <Button onClick={closeModal}>
            取消
          </Button>
          <Button
            loading={authBtnLoading}
            onClick={gotoAuth}
            type="primary"
          >
            去授权
          </Button>

        </>
      )}
      maskClosable={false}
      onCancel={closeModal}
      open={visiblePermission}
      title="提示"
    >
      <div className={styles.permissionTips}>
        当前店铺还没有授权，请进行授权
      </div>
    </Modal>
  );
});

export default ValidPermissionModal;
