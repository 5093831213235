import { Button, Modal, Tabs } from 'antd';
import { observer } from 'mobx-react';
import type { ReactElement } from 'react';
import React, { useEffect, useState } from 'react';
import lifeCyle4 from '../../../assets/images/lifeCycel_4.png';
import lifeCyle1 from '../../../assets/images/lifeCycle_1.png';
import lifeCyle2 from '../../../assets/images/lifeCycle_2.png';
import lifeCyle3 from '../../../assets/images/lifeCycle_3.png';
import lifeCyle5 from '../../../assets/images/lifeCycle_5.png';
import lifeCyle6 from '../../../assets/images/lifeCycle_6.png';
import styles from './lifeCycle.less';

const getUrl = (status) => {
  switch (status) {
    case '1':
      return lifeCyle1;
    case '2':
      return lifeCyle2;
    case '3':
      return lifeCyle3;
    case '4':
      return lifeCyle4;
    case '5':
      return lifeCyle5;
    case '6':
      return lifeCyle6;
  }
};

const LifeCycle = observer((props): ReactElement => {
  const {
    tabList,
    visible,
    defaultTab,
    onCancel,
    setParam,
  } = props;
  const [
    activeTab,
    setActiveTab,
  ] = useState();
  useEffect(() => {
    onTabChange(defaultTab);
  }, [defaultTab]);
  const onTabChange = (activeKey) => {
    setActiveTab(activeKey);
  };
  const tabItems =
    tabList.map((el) => {
      const url = getUrl(el.salesStatus);
      return {
        key: el.salesStatus,
        label: el.salesStatusName,
        children: (
          <div
            className={`${styles.mt27} ${styles.flex}`}
            style={{ flex: 1 }}
          >
            <div className={styles.imgArea}>
              <img
                src={url}
                style={{ width: '303px' }}
              />
            </div>
            <div className={styles.ml30}>
              <div
                className={`${styles.font14} ${styles.cl3} ${styles.font500} ${styles.lh1}`}
                style={{ lineHeight: 1 }}
              >
                生命周期介绍
              </div>
              <div className={`${styles.mt10} ${styles.describe}`}>
                {el.desc}
              </div>
              <div className={`${styles.mt20} ${styles.captions}`}>
                e精灵通过分析您的历史销售数据，为您定制化生成了一套商品画像的判断逻辑，您也可以自己配置关键阈值。
                <span
                  className={styles.config}
                  onClick={setParam}
                >
                  点我去配置
                </span>
              </div>
              <div className={styles.btnWrap}>

                <Button
                  className={styles.btn}
                  onClick={onCancel}
                  type="primary"
                >
                  了解
                </Button>
              </div>
            </div>
          </div>),
      };
    });

  return (
    <Modal
      bodyStyle={{
        padding: '18px',
        borderRadius: '6px',
        height: '369px',
      }}
      closable={false}
      footer={null}
      open={visible}
      width={646}
      wrapClassName={styles.lifeCycle}
    >
      <div className={`${styles.cl3} ${styles.font18} ${styles.font500}`}>
        生命周期介绍
      </div>
      <Tabs
        activeKey={activeTab}
        className={styles.mt24}
        items={tabItems}
        onChange={(activekey) => onTabChange(activekey)}
      />
    </Modal>
  );
});

export default LifeCycle;
