import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Select, Tooltip } from 'antd';
import { EgGrid, NormalProgrammeComponent } from 'egenie-utils';

import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import ValidPermissionModal from '../shelvesNew/validPermissionModal';

// import { PlatformGoods } from '../components/index';
import DownloadPicPackage from '../styleDetail/component/downloadPicPackage/downloadPicPackage';
import { PublishGoods } from '../styleDetail/component/publishGoods/publishGoods';
import LifeCycle from './lifeCycle/lifeCycle';
import LifeCycleParam from './lifeCyclePram/lifeCycleParam';
import NewStrategy from './newStrategy/newStrategy';
import styles from './productAnalysis.less';
import ProductAnalysisStore from './productAnalysisStore';

const store = new ProductAnalysisStore();

@observer
class ProductAnalysis extends Component {
  componentDidMount(): void {
    const searchValue = new URLSearchParams(window.location.href.split('?')[1]).get('gmsStyleNo');
    if (searchValue) {
      store.normalProgramme.filterItems.updateFilterItem([
        {
          field: 'goodsCode',
          value: searchValue,
        },
      ]);
      store.initPage();
      store.searchShopFlag = false;
    } else {
      store.initPage();
    }
  }

  render(): ReactNode {
    const {
      normalProgramme,
      gridModel,
      lifeCycleList,
      rateList,
      dictList,
      shopId,
      onChange,
      newStategyStore,
      defaultTab,
      lifeCycleVisible,
      onLifeCycleClose,
      onLifeCycleShow,
      lifeCycleParam,
      lifeCycleParamVisible,
      closeLifeCycleParam,
      saveLifeCycleParam,
      defaultLifeCycleParam,
      changeLifeCycleParam,
      onLifeCycleParamShow,
      newStrategy,
      lifeCycleParamLoading,
      freshTime,
      lifeCycleClick,
      onSoldOut,
      downloadPicPackageStore,
      publishGoodsStore,
      validPermissionModalStore,
    } = store;
    return (
      <div className={styles.productAnalysis}>
        <div className={`${styles.shopArea} ${styles.h34} ${styles.flex} ${styles.flexBetween} ${styles.flexAlign}`}>
          <div>
            <span className={`${styles.font16} ${styles.font400} ${styles.cl3}`}>
              店铺
            </span>
            <Select
              allowClear
              className={`${styles.h34} ${styles.shop}`}
              onChange={(v) => onChange('shopId', v)}
              placeholder="请选择"
              value={shopId ? shopId : undefined}
            >
              {
                dictList.shopList.map((el) => (
                  <Select.Option
                    key={el.key}
                    value={el.key}
                  >
                    {el.value}
                  </Select.Option>
                ))
              }
            </Select>
          </div>
          <div className={`${styles.font16} ${styles.font400} ${styles.clLight}`}>
            最近更新：
            {freshTime}
          </div>
        </div>
        <div
          className={`${styles.lifeCycle} ${styles.mt10} ${styles.br4}`}
          style={{ display: shopId ? 'block' : 'none' }}
        >
          <div className={`${styles.flex} ${styles.flexBetween} ${styles.clf}`}>
            <span className={`${styles.font16} ${styles.font500} ${styles.mt2}`}>
              商品生命周期
            </span>
            <Button
              className={`${styles.clblue} ${styles.h34}`}
              onClick={onLifeCycleParamShow}
            >
              参数配置
            </Button>
          </div>
          <Row
            className={`${styles.mt2} ${styles.lh1}`}
          >
            {
              lifeCycleList.map((el) => {
                return (
                  <Col
                    className={styles.clf}
                    key={el.salesStatus}
                    span={4}
                  >
                    <div
                      className={`${styles.font16} ${styles.font500} ${styles.pointer}`}
                      onClick={() => lifeCycleClick(Number(el.salesStatus))}
                    >
                      {el.salesStatusName}
                      <InfoCircleOutlined
                        className={`${styles.ml4} ${styles.op6}`}
                        onClick={(e) => onLifeCycleShow(el.salesStatus, e)}
                      />
                    </div>
                    <div
                      className={`${styles.font24} ${styles.font500} ${styles.mt8} ${styles.poRelative} ${styles.pointer}`}
                      onClick={() => lifeCycleClick(Number(el.salesStatus))}
                    >
                      <div className={styles.poRelative}>
                        <span>
                          {el.num}
                        </span>
                        <div className={`${styles.lifeCyclelabel} ${styles.font10}`}>
                          昨日+
                          {el.yesterdayAddNum}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            }
          </Row>
        </div>

        <Row className={`${styles.rate} ${styles.mt10} ${styles.br4} ${styles.pd16}`}>
          {
            rateList.map((el, i) => {
              const {
                name,
                dataMoM,
                dataMoMFlag,
                goodsNum,
                desc,
                key,
                salesProductMoM,
              } = el;
              return (
                <Col
                  className={styles.lh1}
                  key={name}
                  span={4}
                >
                  <div className={`${styles.font12} ${styles.font400} ${styles.clRate}`}>
                    {name}
                    {desc && (
                      <Tooltip
                        placement="top"
                        title={desc}
                      >
                        <InfoCircleOutlined className={`${styles.ml4} ${styles.op6}`}/>
                      </Tooltip>
                    )}
                  </div>
                  <div className={`${styles.font500} ${styles.font24} ${styles.clblue} ${styles.mt10}`}>
                    {goodsNum}
                  </div>
                  <div
                    className={`${styles.font14} ${styles.font400}  ${styles.mt8} ${styles.clgrey}`}
                    style={{ display: salesProductMoM ? 'block' : 'none' }}
                  >
                    在售商品中占比
                    <span className={`${dataMoMFlag === 1 ? styles.clred : styles.clgreen} ${styles.font500} ${styles.ml4}`}>
                      {dataMoMFlag === 1 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                      {dataMoM}
                    </span>
                  </div>
                  <div
                    className={`${styles.font14} ${styles.font400}  ${styles.mt8} ${styles.clgrey}`}
                    style={{ display: dataMoM ? 'block' : 'none' }}
                  >
                    环比
                    <span className={`${dataMoMFlag === 1 ? styles.clred : styles.clgreen} ${styles.font500} ${styles.ml4}`}>
                      {dataMoMFlag === 1 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                      {dataMoM}
                    </span>
                  </div>
                </Col>
              );
            })
          }
        </Row>

        <Card
          size="small"
          style={{ marginTop: 8 }}
        >
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>

        <div className={`${styles.mt10} ${styles.br4} ${styles.font400}`}>
          {/* <Button
            className={`${styles.btns} ${styles.clblue}`}
            onClick={onPutAway}
          >
            上架
          </Button> */}
          <Button
            className={`${styles.btns} ${styles.clblue} ${styles.font400}`}
            onClick={() => onSoldOut()}
          >
            下架
          </Button>
          <Button
            className={`${styles.btns} ${styles.clblue} ${styles.ml10} ${styles.font400}`}
            onClick={newStrategy}
          >
            分配策略
          </Button>
        </div>
        <div
          className={`${styles.flex1} ${styles.mt10}`}
          style={{ height: 'calc(100vh - 400px)' }}
        >
          <EgGrid store={gridModel}/>
        </div>
        <NewStrategy store={newStategyStore}/>
        <PublishGoods store={publishGoodsStore}/>
        <DownloadPicPackage store={downloadPicPackageStore}/>
        <LifeCycle
          defaultTab={defaultTab}
          onCancel={onLifeCycleClose}
          setParam={onLifeCycleParamShow}
          tabList={lifeCycleList}
          visible={lifeCycleVisible}
        />

        <LifeCycleParam
          getDefaultParam={defaultLifeCycleParam}
          lifeCycleParam={lifeCycleParam}
          loading={lifeCycleParamLoading}
          onCancel={closeLifeCycleParam}
          onChange={changeLifeCycleParam}
          onSave={saveLifeCycleParam}
          visible={lifeCycleParamVisible}
        />
        <Modal
          footer={null}
          onCancel={publishGoodsStore.closeSuccess}
          open={publishGoodsStore.successVisible}
        >
          {
            publishGoodsStore.copyStatus ? (
              <div className={styles.tip}>
                <span className={styles.tipIcon}>
                  <CheckOutlined/>
                </span>
                <div className={styles.tipMessage}>
                  复制商品成功
                </div>
                <Button
                  onClick={publishGoodsStore.closeSuccess}
                  type="primary"
                >
                  确定
                </Button>
              </div>
            ) : (
              <div className={styles.tip}>
                <span
                  className={styles.tipIcon}
                  style={{ backgroundColor: '#FB071F' }}
                >
                  <CloseOutlined style={{ fontSize: '72px' }}/>
                </span>
                <div className={styles.tipMessage}>
                  复制失败
                </div>
                <Button
                  onClick={publishGoodsStore.closeSuccess}
                  type="primary"
                >
                  确定
                </Button>
              </div>
            )
          }

        </Modal>
        <ValidPermissionModal store={validPermissionModalStore}/>
      </div>
    );
  }
}

export default ProductAnalysis;
