import { Button, InputNumber, Modal } from 'antd';
import { observer } from 'mobx-react';
import type { ReactElement } from 'react';
import React from 'react';
import styles from '../lifeCycle/lifeCycle.less';

const InputParam = observer((props) => {
  console.log(props);
  return (
    <div style={{ marginLeft: props.position }}>
      <div>
        <InputNumber
          className={styles.input}
          onChange={(v) => {
            props.onChange(props.label, v);
          }}
          placeholder="请输入"
          value={props.value}
        />
        <span style={{ marginLeft: '4px' }}>
          {props.label === 'newDays' ? '天' : '件'}
        </span>
      </div>
      <div
        className={styles.mt4}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '80px',
        }}
      >
        <span className={styles.symbol}>
          小于
        </span>
        <div
          className={styles.dottedLine}
          style={{ height: props.dividerHeight }}
        />
        <span className={styles.symbol}>
          大于
        </span>
      </div>
    </div>
  );
});

const LifeCycleParam = observer((props): ReactElement => {
  const { visible,
    lifeCycleParam: {
      growthRate,
      salesThresholdOne,
      salesThresholdTwo,
      salesThresholdThree,
      newDays,
    },
    onCancel,
    onSave,
    getDefaultParam,
    onChange,
    loading } = props;
  return (
    <Modal
      bodyStyle={{
        padding: '18px',
        borderRadius: '6px',
        height: '506px',
      }}
      closable={false}
      footer={null}
      open={visible}
      width={664}
      wrapClassName={styles.lifeCycle}
    >
      <div className={`${styles.cl3} ${styles.font18} ${styles.font500}`}>
        商品生命周期参数配置
      </div>
      <div className={styles.wrap}>
        <div className={`${styles.flex} ${styles.h54}`}>
          <span className={`${styles.mt10} ${styles.cl2b} ${styles.font14}`}>
            增长率(近3天)
          </span>
          <InputParam
            dividerHeight="113px"
            label="growthRate"
            onChange={onChange}
            position="310px"
            value={growthRate}
          />
        </div>
        <div className={`${styles.flex} ${styles.h54}`}>
          <span className={`${styles.mt10} ${styles.cl2b} ${styles.font14}`}>
            销量(近3天)
          </span>
          <InputParam
            dividerHeight="189px"
            label="salesThresholdOne"
            onChange={onChange}
            position="100px"
            value={salesThresholdOne}
          />
          <InputParam
            dividerHeight="59px"
            label="salesThresholdTwo"
            onChange={onChange}
            position="25px"
            value={salesThresholdTwo}
          />
          <InputParam
            dividerHeight="59px"
            label="salesThresholdThree"
            onChange={onChange}
            position="100px"
            value={salesThresholdThree}
          />
        </div>
        <div className={`${styles.mt10} ${styles.flex} ${styles.h70}`}>
          <div
            className={`${styles.flex} ${styles.flexColumn} ${styles.alignItem} ${styles.ml214}`}
            style={{ width: '100px' }}
          >
            <div className={styles.tags}>
              平销款
            </div>
            <div className={styles.rate}>
              增长率
              {'<'}
              <span>
                {growthRate || '_'}
              </span>
              件
            </div>
            <div className={styles.rate}>
              <span>
                {salesThresholdOne || '_'}
              </span>
              件≤销量
              {'<'}
              <span>
                {salesThresholdTwo || '_'}
              </span>
              件
            </div>
          </div>
          <div
            className={`${styles.flex} ${styles.flexColumn} ${styles.alignItem}`}
            style={{
              width: '100px',
              marginLeft: '40px',
            }}
          >
            <div className={styles.tags}>
              现金牛
            </div>
            <div className={styles.rate}>
              增长率
              {'<'}
              <span>
                {growthRate || '_'}
              </span>
              件
            </div>
            <div className={styles.rate}>
              销量≥
              <span>
                {salesThresholdTwo || '_'}
              </span>
              件
            </div>
          </div>
          <div
            className={`${styles.flex} ${styles.flexColumn} ${styles.alignItem} ${styles.ml25}`}
            style={{ width: '100px' }}
          >
            <div className={styles.tags}>
              潜力款
            </div>
            <div className={styles.rate}>
              增长率≥
              <span>
                {growthRate || '_'}
              </span>
              件
            </div>
            <div className={styles.rate}>
              销量
              {'<'}
              <span>
                {salesThresholdThree || '_'}
              </span>
              件
            </div>
          </div>
          <div
            className={`${styles.flex} ${styles.flexColumn} ${styles.alignItem} ${styles.ml20}`}
            style={{ width: '100px' }}
          >
            <div className={styles.tags}>
              明星款
            </div>
            <div className={styles.rate}>
              增长率≥
              <span>
                {growthRate || '_'}
              </span>
              件
            </div>
            <div className={styles.rate}>
              销量≥
              <span>
                {salesThresholdThree || '_'}
              </span>
              件
            </div>
          </div>
        </div>
        <div className={`${styles.flex} ${styles.h54} ${styles.mt_15}`}>
          <span className={`${styles.mt10} ${styles.cl2b} ${styles.font14}`}>
            上新天数
          </span>
          <InputParam
            dividerHeight="61px"
            label="newDays"
            onChange={onChange}
            position="20px"
            value={newDays}
          />
        </div>
        <div className={`${styles.mt10} ${styles.flex} ${styles.h90}`}>
          <div
            className={`${styles.flex} ${styles.flexColumn}`}
            style={{
              width: '100px',
              alignItems: 'flex-end',
              marginLeft: '10px',
            }}

          >
            <div className={styles.tags}>
              引入款
            </div>
            <div className={styles.rate}>
              增长率
              {'<'}
              <span>
                {growthRate || '_'}
              </span>
              件
            </div>
            <div className={styles.rate}>
              销量
              {'<'}
              <span>
                {salesThresholdOne || '_'}
              </span>
              件
            </div>
            <div className={styles.rate}>
              上新天数
              {'<'}
              <span>
                {newDays || '_'}
              </span>
              天
            </div>
          </div>
          <div
            className={`${styles.flex} ${styles.flexColumn} ${styles.alignItem} ${styles.ml10}`}
            style={{
              width: '100px',
              alignItems: 'flex-start',
            }}
          >
            <div className={styles.tags}>
              滞销款
            </div>
            <div className={styles.rate}>
              增长率
              {'<'}
              <span>
                {growthRate || '_'}
              </span>
              件
            </div>
            <div className={styles.rate}>
              销量
              {'<'}
              <span>
                {salesThresholdOne || '_'}
              </span>
              件
            </div>
            <div className={styles.rate}>
              上新天数≥
              <span>
                {newDays || '_'}
              </span>
              天
            </div>
          </div>
        </div>
      </div>
      <div className={styles.btnWrap}>
        <Button
          className={styles.btn}
          onClick={onCancel}
        >
          取消
        </Button>
        <Button
          className={`${styles.btn} ${styles.ml10}`}
          ghost
          onClick={getDefaultParam}
          type="primary"
        >
          恢复默认值
        </Button>
        <Button
          className={`${styles.btn} ${styles.ml10}`}
          loading={loading}
          onClick={onSave}
          type="primary"
        >
          保存
        </Button>
      </div>
    </Modal>
  );
});

export default LifeCycleParam;
