import { CloseCircleFilled } from '@ant-design/icons';
import arrayMove from 'array-move';
import { observer } from 'mobx-react';
import React, { Component, ReactNode, useState, useRef } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from './publishGoods.less';

// 图片展示卡片

const SortableItem = SortableElement(({ src, sortIndex, store }) => {
  const { onClickDelete } = store;
  return (
    <div
      className="publishGoodsPicture"
      style={{ marginTop: sortIndex > 8 ? '12px' : 0 }}
    >
      <img
        alt=""
        className="img"
        src={src}
      />
      <CloseCircleFilled
        className="delete"
        onMouseDown={(e) => {
          onClickDelete(src);
          e.stopPropagation();
        }}
      />
      {
        sortIndex === 0 ? (
          <span className="coverIcon">
            封面
          </span>
        ) : ''
      }
    </div>
  );
});

const SortableList = SortableContainer(observer(({ items, store }) => {
  return (
    <div className={styles.mianPictures}>
      {items && items.map((el, index) => (
        <SortableItem
          index={index}
          key={`item-${el}`}
          sortIndex={index}
          src={el}
          store={store}
        />
      ))}
    </div>
  );
}));

export default SortableList;
