
import { message } from 'antd';
import type { FormInstance } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import React from 'react';
import { api } from '../../../utils';

class Store {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public parent;

  @observable public shopId;

  @observable public authBtnLoading = false;

  @observable public visiblePermission = false;

  @action public gotoAuth = () => {
    this.authBtnLoading = true;
    request<BaseData<any>>({ url: `${api.gotoAuth}/${this.shopId}` }).then((res) => {
      this.authBtnLoading = false;
      window.open(res.data);
      this.visiblePermission = false;
    })
      .catch(() => this.authBtnLoading = false);
  };

  @action public closeModal = () => {
    this.visiblePermission = false;
  };
}

export default Store;
