import { Empty, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './downloadPicPackage.less';
import type { IDownloadPicPackageStore } from './downloadPicPackageStore';

interface IProps {
  store: IDownloadPicPackageStore;
}

const DownloadPicPackage = observer((props: IProps): JSX.Element => {
  const {
    visible,
    onModalClose,
    groupPics,
    onClickDownload,
  } = props.store;
  return (
    <Modal
      bodyStyle={{
        maxHeight: '358px',
        overflowY: 'auto',
      }}
      destroyOnClose
      footer={null}
      okText="下载"
      onCancel={onModalClose}
      open={visible}
      title="下载图片包"
      width={560}
    >
      <div className={styles.container}>
        <div className={styles.tip}>
          可下载多套图片，包含主图，颜色图，详情图
        </div>
        <div className={styles.content}>
          <div className={styles.picsContainer}>
            {
              groupPics.length ? (
                groupPics.map((el, index) => {
                  return (
                    <div
                      className={styles.picsItem}
                      key={el.groupId}
                    >
                      <div
                        className={styles.imgBorder}
                      >
                        <img
                          className={styles.img}
                          src={el.smallPicUrl}
                        />
                        {el.groupId === 0 && (
                          <span className={styles.mainTips}>
                            已选主图
                          </span>
                        )}
                      </div>
                      <div className={styles.imgsCate}>
                        {el.groupName}
                      </div>
                      <div
                        className={styles.downloadBtn}
                        onClick={() => onClickDownload(el.groupId, el.groupName)}
                      >
                        下载
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty/>
              )
            }
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default DownloadPicPackage;
