import { message } from 'antd';
import arrayMove from 'array-move';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { observable, computed, action, toJS } from 'mobx';
import { api } from '../../../../utils';
import PublishSuccessStore from '../publishSuccess/publishSuccessStore';
import type { IShopItem, IGetShopAndStrategy, IGetPictures, ISubmitData } from './interface';

export default class PublishGoodsStore {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public publishSuccessStore = new PublishSuccessStore({ parent: this });

  @observable public formRef;

  @observable public title: string;

  // @observable public mainPics = [];

  @observable public pictureStore = [];

  @observable public strategyList = [];

  @observable public shopList = [];

  @observable public parent;

  @observable public type: number;

  @observable public dynamicIds;

  @observable public visible = false;

  @observable public submitLoading = false;

  @observable public successVisible = false;

  @observable public copyStatus: boolean; // 平台商品模态框显隐，不同与选款成功弹窗
  
  @observable public goodsId: number; // posgoodsId

  @observable public gmsGoodsId: number; // gmsGoodsId

  @observable public vendorShopId: number | null;

  @observable public mainPictures: string[];

  @observable public currentPicGroup;

  @observable public submitData: ISubmitData;

  // mainPics:主图， gmsGoodsId：type=1， goodsId: 保存提交时使用的id， title： 弹窗的名称， type: 保存时的type, dynamicId: 埋点所需的动态id， id: 款式管理和平台商品管理查询主图用的posGoodsId
  @action public onFullModalShow = (mainPics: string | string[], gmsGoodsId: number | string, goodsId: number, currentPicGroup: number | null, title: string, type: number, dynamicIds: number | string, id?: number): void => { // id: 请求款式管理的图片库
    Object.assign(this, {
      visible: true,
      goodsId,
      title,
      type,
      gmsGoodsId,
      currentPicGroup,
      dynamicIds,
    });
    console.log('this.currentPicGroup', this.currentPicGroup);
    
    // 款式管理和平台商品管理无主图，请求主图
    if (Array.isArray(mainPics)) {
      this.mainPictures = [...new Set(_.cloneDeep(mainPics))];
    } else {
      this.getGoodsMainPictures();
    }
    
    // 请求图片库
    if (id) { // 存在posGoodsId请求
      this.getPictures(id);
    } else if (id === null) { // 不存在posGoodsId不请求
      console.log('posGoodsId');
    } else if (goodsId) { // 款式管理未选态若不存在goodsId则不请求
      this.getPictures();
    }
      
    this.getShopList();
    this.getStrategy();
  };

  @action public onFullModalClose = (): void => {
    this.visible = false;
    this.mainPictures = [];
    this.pictureStore = [];
    this.formRef.resetFields();
  };

  @action public setRef = (ref): void => {
    this.formRef = ref;
  };

  @action public getShopList = async(): Promise<void> => {
    const res = await request<BaseData<IShopItem[]>>({
      url: api.getClassifyShop,
      method: 'post',
    });
    this.shopList = res.data.map((item, index) => {
      return {
        title: item.platformTypeName,
        value: item.platformType,

        // key: `0-${index}`,
        children: item.pcShopVos.map((v, i) => {
          return {
            title: v.shopName,
            value: v.shopId,

            // key: `0-${index}-${i}`,
          };
        }),
      };
    });

    console.log('shopList', toJS(this.shopList));
  };

  @action public getStrategy = async(): Promise<void> => {
    const res = await request<BaseData<IGetShopAndStrategy>>({
      url: api.getShopAndStrategy,
      method: 'post',
      data: { commonDictList: ['strategyList']},
    });

    this.strategyList = res.data.strategyList;
  };

  @action public closeSuccess = () => {
    this.successVisible = false;
  };

  // 平台商品管理获取主图
  @action public getGoodsMainPictures = async(): Promise<void> => {
    const res = await request <BaseData<any>>({
      url: api.getGoodsMainPics,
      method: 'post',
      data: { gmsGoodsId: this.goodsId },
    });
    console.log('res.data.mainPics', res.data.mainPics);
    this.mainPictures = [];
    res.data && res.data.forEach((item) => {
      this.mainPictures.push(item.url);
    });
  };

  // 请求图片库， 根据不同情况传不同的goodsId
  @action public getPictures = async(goodsId?: number): Promise<void> => {
    const res = await request<BaseData<IGetPictures[]>>({ url: `${api.getPictures }?goodsId=${goodsId ? goodsId : this.goodsId}` });
    res.data && res.data.forEach((item) => {
      this.pictureStore = [
        ...this.pictureStore,
        ...item.mainPicUrls,
      ];
    });

    console.log('this.pictureStore', this.pictureStore);
  };
  
  @action public onPictureClick = (src: string): void => {
    try {
      const index = this.mainPictures.indexOf(src);
      if (index === -1) {
        this.mainPictures.push(src);
      } else {
        this.mainPictures.splice(index, 1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 图片排序
  @action public onSortEnd = ({ oldIndex, newIndex }): void => {
    console.log(oldIndex, newIndex);
    this.mainPictures = arrayMove(this.mainPictures, oldIndex, newIndex);
  };

  @action public onClickDelete = (src: string): void => {
    this.mainPictures = this.mainPictures.filter((value, index) => {
      return value != src;
    });
  };

  @action public onSubmit = (): any => {
    const formData = this.formRef.getFieldsValue();
    console.log(toJS(this.shopList));
    console.log(formData);
    try {
      const shopList = [];
      const strategyList = [];
      formData.shop && formData.shop.map((item) => {
        this.shopList.forEach((value) => {
          value.children.forEach((el) => {
            if (item === el.value) {
              shopList.push({
                shopId: el.value,
                shopName: el.title,
                platformType: value.value,
              });
            }
          });
        });
      });
      formData.strategy && formData.strategy.map((item) => {
        this.strategyList.forEach((value) => {
          if (value.key === item) {
            strategyList.push({
              gmsStrategyId: value.key,
              name: value.value,
            });
          }
        });
      });
      
      if (shopList.length === 0) {
        return message.warning('未选择店铺');
      } else if (this.mainPictures && this.mainPictures.length === 0) {
        return message.warning('请选择主图');
      }

      this.submitData = {
        shopInfo: shopList.map((item) => {
          return {
            ...item,
            strategyList,
          };
        }),

        // type: this.type,
        mainPicUrls: this.mainPictures,
        goodsId: this.gmsGoodsId ? this.gmsGoodsId : this.goodsId,
      };
      this.submit();
    } catch (err) {
      console.log(err);
    }
  };

  @action public submit = () => {
    this.submitLoading = true;
    request<BaseData<any>>({
      url: api.submitCopyGoods,
      method: 'post',
      data: this.submitData,
    }).then((res) => {
      this.submitLoading = false;
      if (res.status === 'Successful') {
        const { gmsStyleNo, gmsGoodsId } = res.data.gmsGoodsInfoVos[0]; // 获取gmsStyleNo查询商品，gmsGoodsId用于下载图片包
        this.onFullModalClose(); // 关闭选款或复制平台商品弹窗
        this.publishSuccessStore.onSuccessFullModalShow(this.submitData, gmsStyleNo, gmsGoodsId, this.dynamicIds, res.data.gmsGoodsInfoVos, res.data?.gmsGoodsInfoVos || []); // 打开成功弹窗
        this.successVisible = true;
        this.copyStatus = true;
      } else {
        this.copyStatus = false;
      }
    })
      .catch(() => this.submitLoading = false);
  };
}

export type IPublishGoods = PublishGoodsStore;
