import { CloseCircleFilled, CheckOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Select, TreeSelect, Empty } from 'antd';
import { FullModal } from 'egenie-utils';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import MainPictures from './mainPictures';
import styles from './publishGoods.less';
import type { IPublishGoods } from './publishGoodsStore';

const { Option } = Select;

@observer
class PublishGoods extends React.Component<{ store?: IPublishGoods; }> {
  render(): ReactNode {
    const { visible,
      onFullModalClose,
      setRef,
      onSortEnd,
      mainPictures,
      onPictureClick,
      pictureStore,
      shopList,
      strategyList,
      onSubmit,
      title,
      submitLoading,
      vendorShopId } = this.props.store;
    console.log(submitLoading, 'submitLoading');
    const operation = (
      <div>
        <Button
          onClick={onFullModalClose}
          style={{ marginRight: 8 }}
        >
          取消
        </Button>
        <Button

          // disabled={isAbleSubmitBtn}
          htmlType="submit"
          loading={
            submitLoading
          }
          onClick={onSubmit}
          type="primary"
        >
          提交
        </Button>
      </div>
    );
    return (
      <FullModal
        onCancel={onFullModalClose}
        operation={operation}
        title={title}
        visible={visible}
      >
        <div className={styles.container}>
          <Form
            className={styles.form}
            ref={setRef}
          >
            <Form.Item
              label="上款店铺"
              name="shop"
              required
            >
              <TreeSelect
                allowClear
                multiple
                showArrow
                style={{ width: 330 }}
                treeCheckable
                treeData={shopList}
                treeDefaultExpandAll
              />
            </Form.Item>
            <Form.Item
              label="商品策略"
              name="strategy"
            >
              <Select
                allowClear
                mode="multiple"
                showArrow
                style={{ width: '330px' }}
              >
                {
                  strategyList && strategyList.map((el) => {
                    return (
                      <Option
                        key={el.key}
                        value={el.key}
                      >
                        {el.value}
                      </Option>
                    );
                  })
                }
              </Select>
            </Form.Item>
          </Form>
          <div>
            <header className={styles.mianPictureTitle}>
              主图
            </header>
            <div className={styles.mianPictureContent}>
              <div className={styles.mainPictures}>
                <header className={styles.subTitle}>
                  <span>
                    已选主图
                  </span>
                  （可从款式图片库里选择主图，并拖拽排序）
                </header>
                {
                  mainPictures && mainPictures.length > 0 ? (
                    <MainPictures
                      axis="x"
                      items={mainPictures && mainPictures}
                      onSortEnd={onSortEnd}
                      store={this.props.store}
                    />
                  ) : (
                    <div className={styles.pictureStore}>
                      <Empty/>
                    </div>

                  )
                }
                
                <div/>
              </div>
              <div>
                <header className={styles.subTitle}>
                  图片款式库
                </header>
                <div className={styles.pictureStore}>
                  {
                    pictureStore.length > 0 ? (
                      pictureStore.map((el, index) => {
                        return (
                          <div
                            className={styles.picture}
                            key={el}
                            onClick={() => onPictureClick(el)}
                            style={{ marginTop: index > 8 ? '12px' : '0px' }}
                          >
                            <img
                              alt=""
                              className={styles.img}
                              src={el}
                            />
                            <span
                              className={styles.checkbox}
                              style={mainPictures && mainPictures.indexOf(el) !== -1 ? {
                                border: 'none',
                                backgroundColor: '#1978FF',
                              } : {}}
                            >
                              <CheckOutlined style={mainPictures && mainPictures.indexOf(el) !== -1 ? {} : { display: 'none' }}/>
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <Empty/>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </FullModal>
    );
  }
}

export { PublishGoods };
